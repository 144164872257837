/***
 * @patr
 */

import { Component } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Redux
import { MessageActions } from "../redux/message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transition: false,
    };
  }

  eventListen = () => {
    let { messageActions } = this.props;
    messageActions.showMessage({ show: false });
    document.removeEventListener("click", this.eventListen, true);
    document.removeEventListener("touchstart", this.eventListen, true);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { messageActions, message } = this.props;
    if (
      nextProps.message.show &&
      this.props.message.show !== nextProps.message.show
    ) {
      if (nextProps.message.clickoff) {
        document.addEventListener("touchstart", this.eventListen, true);
        document.addEventListener("click", this.eventListen, true);
      } else if (!nextProps.message.load) {
        this.popupMessageTimeout = setTimeout(() => {
          messageActions.showMessage({ show: false });
        }, message.timeout);
      }
    }
  }

  render() {
    let { message } = this.props;
    return (
      <div className={css(styles.popupMessage, !message.show && styles.hide)}>
        {!message.load &&
          (message.error ? (
            <FontAwesomeIcon
              icon={faTimes}
              color="#fff"
              className={css(styles.loadingSpinner)}
            />
          ) : (
            <FontAwesomeIcon
              icon={faCheck}
              color="#fff"
              className={css(styles.loadingSpinner)}
            />
          ))}
        {!message.load ? (
          <div className={css(styles.message)}>{message.message}</div>
        ) : (
          <FontAwesomeIcon
            icon={faSpinnerThird}
            color="#fff"
            spin
            className={css(styles.loadingSpinner)}
          />
          // <i
          //   className={"fad fa-spinner-third fa-spin"}
          //   style={inlineStyle.check}
          //   color="#fff"
          // />
        )}
      </div>
    );
  }
}

const inlineStyle = {
  check: {
    color: "#FFF",
    fontSize: 40,
  },
};

var styles = StyleSheet.create({
  popupMessage: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(0, 0, 0, .8)",
    padding: "16px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 999999,
    whiteSpace: "pre-wrap",
  },
  hide: {
    display: "none",
  },
  message: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: "300",
    textAlign: "center",
    fontFamily: "Roboto",
    whiteSpace: "pre-wrap",
  },
  loadingSpinner: {
    fontSize: 40,
  },
});

const mapStateToProps = (state) => ({
  message: state.message,
});

const mapDispatchToProps = (dispatch) => ({
  messageActions: bindActionCreators(MessageActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
